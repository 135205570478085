import React, { useContext, useState, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Helmet } from "react-helmet"

import { WPContext } from "../contexts/wpContext"
import HTMLHeader from "../components/htmlHeader"

const Content = styled.div`
  width: calc(100% - 230px);
  display: flex;
  flex-direction: column;
  margin: 60px 0 0 0;

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    margin: 30px 0 0 0;
  }
`

const LandingContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;

  @media (max-width: 700px) {
    grid-template-columns: 100%;
  }
`

const LandingGallerySection = styled(Link)`
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px 0 0;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #c4c4c4;
  text-decoration: none;

  @media (max-width: 700px) {
    border-right: none;
    border-bottom: 1px solid #c4c4c4;
    padding: 0 0 20px 0;
  }
`

const LandingGalleryImg = styled.img`
  width: 100%;
  margin-bottom: 20px;
  background-color: #c4c4c4;
`

const LandingGalleryHeader = styled.h2`
  font-size: 36px;
  color: black;
  font-family: "MinionPro";
  margin: 0 0 10px 0;
  font-weight: 400;

  @media (max-width: 700px) {
    font-size: 32px;
  }
`

const LandingGalleryDesc = styled.p`
  font-size: 14px;
  color: black;
  font-family: "franklinGothic";
  margin: 0;
  max-width: 400px;
`

const LandingArticlesSection = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #c4c4c4;

  @media (max-width: 700px) {
    border: none;
  }
`

const LandingArticleWrapper = styled(Link)`
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
  box-sizing: border-box;
  padding: 0 30px;
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  grid-gap: 0 20px;
  margin: 0 0 20px 0;
  text-decoration: none;

  &:last-of-type {
    border: none;
    margin: 0;
  }

  @media (max-width: 700px) {
    grid-template-columns: 100%;
    padding: 20px 0;
    margin: 0;
  }
`

const LandingArticleImg = styled.img`
  background-color: #c4c4c4;
  width: 100%;
  margin: 0 0 20px 0;

  @media (max-width: 700px) {
    margin: 0;
  }
`

const LandingArticleTextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const LandingArticleTitle = styled.h3`
  font-size: 18px;
  color: black;
  font-family: "MinionPro";
  font-weight: 400;
  margin: 0 0 2px 0;
`

const LandingArticleCredit = styled.p`
  font-size: 12px;
  color: #294973;
  font-family: "franklinGothic";
  font-weight: 400;
  margin: 0 0 15px 0;
`

const LandingArticleExcerpt = styled.div`
  font-size: 14px;
  color: black;
  font-family: "franklinGothic";
  font-weight: 400;
  margin: 0 0 20px 0;
`

const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 50px 0 0 0;

  @media (max-width: 700px) {
    margin: 40px 0 0 0;
  }
`

const SectionHeader = styled.div`
  width: 100%;
  height: 100px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(250, 200, 10, 0.5046393557422969) 100%
  );
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0 0 50px 0;

  @media (max-width: 700px) {
    margin: 0 0 30px 0;
  }
`

const SectionHeaderTitle = styled.h4`
  font-size: 48px;
  color: #294973;
  font-weight: 400;
  font-family: "MinionPro";
  margin: 0 0 0 50px;

  @media (max-width: 700px) {
    margin: 0 0 0 15px;
    font-size: 36px;
  }
`

const SectionHeaderLink = styled(Link)`
  font-size: 14px;
  color: black;
  font-family: "franklinGothic";
  text-decoration: none;
  margin: 0 30px 0 0;

  @media (max-width: 700px) {
    margin: 0 15px 0 0;
  }
`

const SectionArticlesContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0 30px;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-gap: 30px 0;
  }
`

const SectionArticleWrapper = styled(Link)`
  border-right: 1px solid #c4c4c4;
  box-sizing: border-box;
  padding: 0 30px 0 0;
  text-decoration: none;

  &:last-child {
    border: none;
  }

  @media (max-width: 700px) {
    border-right: none;
    border-bottom: 1px solid #c4c4c4;
    padding: 0 0 20px 0;
  }
`

const SectionArticleImg = styled.img`
  width: 100%;
  height: 200px;
  background-color: #c4c4c4;
  margin: 0 0 20px 0;
`

const SectionArticleTitle = styled.h5`
  font-size: 18px;
  color: black;
  font-weight: 400;
  font-family: "MinionPro";
  margin: 0 0 2px 0;
`

const SectionArticleCredit = styled.p`
  font-size: 12px;
  color: #294973;
  font-family: "franklinGothic";
  font-weight: 400;
  margin: 0 0 15px 0;
`

const SectionArticleExcerpt = styled.div`
  font-size: 14px;
  color: black;
  font-family: "franklinGothic";
  font-weight: 400;
  margin: 0 0 10px 0;
`

const SectionArticleDate = styled.p`
  font-size: 12px;
  color: #294973;
  font-family: "franklinGothic";
  font-weight: 400;
  margin: 0;
`

const IndexPage = () => {
  const {
    skuleArticles,
    opinionArticles,
    studentLifeArticles,
    galleryArticles,
  } = useContext(WPContext)

  const [skulePhotoArticle, setSkulePhotoArticle] = useState()
  const [studentPhotoArticle, setStudentPhotoArticle] = useState()
  const [opinionPhotoArticle, setOpinionPhotoArticle] = useState()
  const [galleryPhotoArticle, setGalleryPhotoArticle] = useState()

  const [skuleNo, setSkuleNo] = useState()
  const [studentNo, setStudentNo] = useState()
  const [opinionNo, setOpinionNo] = useState()

  useEffect(() => {
    let tempDiv = document.createElement("DIV")
    let tempDiv2 = document.createElement("DIV")
    let foundSkuleArticle = {}
    let foundStudentArticle = {}
    let foundOpinionArticle = {}
    let foundGalleryArticle = {}

    foundSkuleArticle = skuleArticles.find((post, i) => {
      if (post.node.featured_media) {
        setSkuleNo(i)
        return post
      }
    })
    tempDiv.innerHTML = foundSkuleArticle.node.title
    foundSkuleArticle.node.title = tempDiv.innerText
    tempDiv2.innerHTML = foundSkuleArticle.node.excerpt
    foundSkuleArticle.node.excerpt = tempDiv2.innerText

    foundStudentArticle = studentLifeArticles.find((post, i) => {
      if (post.node.featured_media) {
        setStudentNo(i)
        return post
      }
    })
    tempDiv.innerHTML = foundStudentArticle.node.title
    foundStudentArticle.node.title = tempDiv.innerText
    tempDiv2.innerHTML = foundStudentArticle.node.excerpt
    foundStudentArticle.node.excerpt = tempDiv2.innerText

    foundOpinionArticle = opinionArticles.find((post, i) => {
      if (post.node.featured_media) {
        setOpinionNo(i)
        return post
      }
    })
    tempDiv.innerHTML = foundOpinionArticle.node.title
    foundOpinionArticle.node.title = tempDiv.innerText
    tempDiv2.innerHTML = foundOpinionArticle.node.excerpt
    foundOpinionArticle.node.excerpt = tempDiv2.innerText

    foundGalleryArticle = galleryArticles[0]
    tempDiv.innerHTML = foundGalleryArticle.node.title
    foundGalleryArticle.node.title = tempDiv.innerText

    setSkulePhotoArticle(foundSkuleArticle)
    setStudentPhotoArticle(foundStudentArticle)
    setOpinionPhotoArticle(foundOpinionArticle)
    setGalleryPhotoArticle(foundGalleryArticle)
  }, [])

  const skuleSectionArticles = skuleArticles.slice(0, 4).map((post, i) => {
    if (i == skuleNo) {
      return
    } else if (skuleNo > 2 && i == 3) {
      return
    } else {
      let dateString = post.node.date
      let arr_date = dateString.slice(0, 10).split("-"),
        months = [
          "",
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ]
      let properDate =
        months[parseInt(arr_date[1])] + " " + arr_date[2] + ", " + arr_date[0]

      return (
        <SectionArticleWrapper to={`/articles/${post.node.slug}`}>
          {post.node.featured_media ? (
            <SectionArticleImg
              src={post.node.featured_media.source_url}
            ></SectionArticleImg>
          ) : (
            <></>
          )}
          <SectionArticleTitle
            dangerouslySetInnerHTML={{
              __html: post.node.title,
            }}
          ></SectionArticleTitle>
          <SectionArticleCredit>
            By {post.node.author.name}
          </SectionArticleCredit>
          <SectionArticleExcerpt
            dangerouslySetInnerHTML={{
              __html: post.node.excerpt
                .substring(0, post.node.excerpt.indexOf(" <a"))
                .concat("..."),
            }}
          ></SectionArticleExcerpt>
          <SectionArticleDate>{properDate}</SectionArticleDate>
        </SectionArticleWrapper>
      )
    }
  })

  const studentSectionArticles = studentLifeArticles
    .slice(0, 4)
    .map((post, i) => {
      if (i == studentNo) {
        return
      } else if (studentNo > 2 && i == 3) {
        return
      } else {
        let dateString = post.node.date
        let arr_date = dateString.slice(0, 10).split("-"),
          months = [
            "",
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ]
        let properDate =
          months[parseInt(arr_date[1])] + " " + arr_date[2] + ", " + arr_date[0]

        return (
          <SectionArticleWrapper to={`/articles/${post.node.slug}`}>
            {post.node.featured_media ? (
              <SectionArticleImg
                src={post.node.featured_media.source_url}
              ></SectionArticleImg>
            ) : (
              <></>
            )}
            <SectionArticleTitle
              dangerouslySetInnerHTML={{
                __html: post.node.title,
              }}
            ></SectionArticleTitle>
            <SectionArticleCredit>
              By {post.node.author.name}
            </SectionArticleCredit>
            <SectionArticleExcerpt
              dangerouslySetInnerHTML={{
                __html: post.node.excerpt
                  .substring(0, post.node.excerpt.indexOf(" <a"))
                  .concat("..."),
              }}
            ></SectionArticleExcerpt>
            <SectionArticleDate>{properDate}</SectionArticleDate>
          </SectionArticleWrapper>
        )
      }
    })

  const opinionSectionArticles = opinionArticles.slice(0, 4).map((post, i) => {
    if (i == opinionNo) {
      return
    } else if (opinionNo > 2 && i == 3) {
      return
    } else {
      let dateString = post.node.date
      let arr_date = dateString.slice(0, 10).split("-"),
        months = [
          "",
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ]
      let properDate =
        months[parseInt(arr_date[1])] + " " + arr_date[2] + ", " + arr_date[0]

      return (
        <SectionArticleWrapper to={`/articles/${post.node.slug}`}>
          {post.node.featured_media ? (
            <SectionArticleImg
              src={post.node.featured_media.source_url}
            ></SectionArticleImg>
          ) : (
            <></>
          )}
          <SectionArticleTitle
            dangerouslySetInnerHTML={{
              __html: post.node.title,
            }}
          ></SectionArticleTitle>
          <SectionArticleCredit>
            By {post.node.author.name}
          </SectionArticleCredit>
          <SectionArticleExcerpt
            dangerouslySetInnerHTML={{
              __html: post.node.excerpt
                .substring(0, post.node.excerpt.indexOf(" <a"))
                .concat("..."),
            }}
          ></SectionArticleExcerpt>
          <SectionArticleDate>{properDate}</SectionArticleDate>
        </SectionArticleWrapper>
      )
    }
  })

  return (
    <>
      <HTMLHeader />
      <Content>
        <LandingContainer>
          {galleryPhotoArticle ? (
            <LandingGallerySection
              to={`/articles/${galleryPhotoArticle.node.slug}`}
            >
              <LandingGalleryImg
                src={galleryPhotoArticle.node.featured_media.source_url}
              ></LandingGalleryImg>
              <LandingGalleryHeader>
                {galleryPhotoArticle.node.title}
              </LandingGalleryHeader>
              <LandingGalleryDesc>
                See more photos of the event and other centrespreads from past
                issues in the gallery. Photograhy by Dina Castelletto.{" "}
              </LandingGalleryDesc>
            </LandingGallerySection>
          ) : (
            <LandingGallerySection>
              <LandingGalleryImg></LandingGalleryImg>
              <LandingGalleryHeader>Godiva's Crown</LandingGalleryHeader>
              <LandingGalleryDesc>
                See more photos and other centrespreads from past issues in the
                gallery. Photograhy by Dina Castelletto.{" "}
              </LandingGalleryDesc>
            </LandingGallerySection>
          )}

          <LandingArticlesSection>
            {skulePhotoArticle ? (
              <LandingArticleWrapper
                to={`/articles/${skulePhotoArticle.node.slug}`}
              >
                <LandingArticleTextWrapper>
                  <LandingArticleTitle>
                    {skulePhotoArticle.node.title}
                  </LandingArticleTitle>
                  <LandingArticleCredit>
                    By {skulePhotoArticle.node.author.name}
                  </LandingArticleCredit>
                  <LandingArticleExcerpt>
                    {skulePhotoArticle.node.excerpt.substring(
                      0,
                      skulePhotoArticle.node.excerpt.indexOf(
                        " Continue Reading"
                      )
                    )}
                    ...
                  </LandingArticleExcerpt>
                </LandingArticleTextWrapper>
                <LandingArticleImg
                  src={skulePhotoArticle.node.featured_media.source_url}
                ></LandingArticleImg>
              </LandingArticleWrapper>
            ) : (
              <></>
            )}
            {opinionPhotoArticle ? (
              <LandingArticleWrapper
                to={`/articles/${opinionPhotoArticle.node.slug}`}
              >
                <LandingArticleTextWrapper>
                  <LandingArticleTitle>
                    {opinionPhotoArticle.node.title}
                  </LandingArticleTitle>
                  <LandingArticleCredit>
                    By {opinionPhotoArticle.node.author.name}
                  </LandingArticleCredit>
                  <LandingArticleExcerpt>
                    {opinionPhotoArticle.node.excerpt.substring(
                      0,
                      opinionPhotoArticle.node.excerpt.indexOf(
                        " Continue Reading"
                      )
                    )}
                    ...
                  </LandingArticleExcerpt>
                </LandingArticleTextWrapper>
                <LandingArticleImg
                  src={opinionPhotoArticle.node.featured_media.source_url}
                ></LandingArticleImg>
              </LandingArticleWrapper>
            ) : (
              <></>
            )}
            {studentPhotoArticle ? (
              <LandingArticleWrapper
                to={`/articles/${studentPhotoArticle.node.slug}`}
              >
                <LandingArticleTextWrapper>
                  <LandingArticleTitle>
                    {studentPhotoArticle.node.title}
                  </LandingArticleTitle>
                  <LandingArticleCredit>
                    By {studentPhotoArticle.node.author.name}
                  </LandingArticleCredit>
                  <LandingArticleExcerpt>
                    {studentPhotoArticle.node.excerpt.substring(
                      0,
                      studentPhotoArticle.node.excerpt.indexOf(
                        " Continue Reading"
                      )
                    )}
                    ...
                  </LandingArticleExcerpt>
                </LandingArticleTextWrapper>
                <LandingArticleImg
                  src={studentPhotoArticle.node.featured_media.source_url}
                ></LandingArticleImg>
              </LandingArticleWrapper>
            ) : (
              <></>
            )}
          </LandingArticlesSection>
        </LandingContainer>
        <SectionContainer>
          <SectionHeader>
            <SectionHeaderTitle>SKULE™</SectionHeaderTitle>
            <SectionHeaderLink to="/skule">See all</SectionHeaderLink>
          </SectionHeader>
          <SectionArticlesContainer>
            {skuleSectionArticles}
          </SectionArticlesContainer>
        </SectionContainer>
        <SectionContainer>
          <SectionHeader>
            <SectionHeaderTitle>STUDENT LIFE</SectionHeaderTitle>
            <SectionHeaderLink to="/student-life">See all</SectionHeaderLink>
          </SectionHeader>
          <SectionArticlesContainer>
            {studentSectionArticles}
          </SectionArticlesContainer>
        </SectionContainer>
        <SectionContainer>
          <SectionHeader>
            <SectionHeaderTitle>OPINIONS</SectionHeaderTitle>
            <SectionHeaderLink to="/opinions">See all</SectionHeaderLink>
          </SectionHeader>
          <SectionArticlesContainer>
            {opinionSectionArticles}
          </SectionArticlesContainer>
        </SectionContainer>
      </Content>
    </>
  )
}

export default IndexPage
